<template>
    <div class="projects-showcase">
        <div class="outer-container">
            <div class="header-section">
                <h2 class="header-title">作品展示</h2>
                <hr class="divider-line"/>
            </div>

            <div v-for="(item, index) in processedItems" :key="index" class="project-item">
                <el-image :src="item.imagePath" class="project-image"></el-image>
                <p class="project-description">{{ item.description }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import projectShowcaseData from '@/assets/datas/resume_project_showcase.json';

export default {
    name: 'ShowCasePage',
    data() {
        return {
            projectItems: projectShowcaseData
        };
    },
    computed: {
        processedItems() {
            return this.projectItems.map(item => ({
                ...item,
                imagePath: require('@/assets/images/projectshow/' + item.imagePath)
            }));
        }
    }
}
</script>

<style scoped>
.outer-container {
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 20px;
}
@media print {
  .outer-container {
    width: 95%;
  }
}


.header-section {
    margin-bottom: 20px;
}

.header-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #525252;
}

.divider-line {
    width: 100%;
    border: 0.5px solid #ccc;
    margin-bottom: 20px;
}

.project-item {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-image {
    width: 80%;
    height: auto;
    margin-bottom: 10px;
}

.project-description {
    font-size: 12px;
    color: #999;
    text-align: center;
}
</style>
