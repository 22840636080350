<template>
    <div class="portfolio-page">
        <ResumePage />
        <ProjectPage />
        <WorkExperiencePage />
        <ShowCasePage />
    </div>
</template>

<script>
import ResumePage from '@/views/pages/resume/ResumePage.vue';
import ProjectPage from '@/views/pages/resume/ProjectPage.vue';
import WorkExperiencePage from '@/views/pages/resume/WorkExperiencePage.vue';  
import ShowCasePage from '@/views/pages/resume/ShowCasePage.vue';  

export default {
    name: 'PortfolioPage',
    components: {
        ResumePage,
        ShowCasePage,
        ProjectPage,
        WorkExperiencePage
    }
}
</script>

<style>
.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>
