<template>
    <div class="home-page">
    </div>
  </template>
  
  <script>   
  export default {
    name: 'HomePage',
    beforeRouteEnter(to, from, next) {
      next('/resume'); // 重定向到 /resume
    }
  }
  </script>
  
<style scoped>
.home-page {
  height: 100%;
  widows: 100%;
}


</style>
  