import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/views/pages/HomePage.vue'
import TutorialPage from '@/views/pages/TutorialPage.vue'
import GalleryPage from '@/views/pages/GalleryPage.vue'
import TechPage from '@/views/pages/TechPage.vue'
import TechShowCasePage from '@/views/pages/gallery/TechShowCasePage.vue'

import PortfolioPage from '@/views/pages/PortfolioPage.vue'
import ResumePage from '@/views/pages/resume/ResumePage.vue'
import ProjectPage from '@/views/pages/resume/ProjectPage.vue'
import WorkExperiencePage from '@/views/pages/resume/WorkExperiencePage.vue'
import GalleryShowCasePage from '@/views/pages/gallery/GalleryShowCasePage.vue'

import OutputPage from '@/views/pages/OutputPage.vue'
// ... 其他页面

const routes = [
  { path: '/', component: HomePage },
  { path: '/resume', component: PortfolioPage },
  { path: '/resume/home', component: ResumePage },
  { path: '/resume/project', component: ProjectPage },
  { path: '/resume/work', component: WorkExperiencePage },
  
  { path: '/tutorial', component: TutorialPage },
  { path: '/gallery', component: GalleryPage },
  { path: '/tech', component: TechPage},
  { path: '/tech/showcase', component: TechShowCasePage},
  { path: '/gallery/showcase', component: GalleryShowCasePage },

  { path: '/output', component: OutputPage },
  // ... 其他路由
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
