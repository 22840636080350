<template>
    <div class="portfolio-page">
        <ResumePage />
        <ProjectPage />
        <WorkExperiencePage />
        <ShowCasePage />
        <TechShowCasePage />
        <GalleryShowCasePage />
    </div>
</template>

<script>
import ResumePage from '@/views/pages/resume/ResumePage.vue';
import ProjectPage from '@/views/pages/resume/ProjectPage.vue';
import WorkExperiencePage from '@/views/pages/resume/WorkExperiencePage.vue';  
import ShowCasePage from '@/views/pages/resume/ShowCasePage.vue';  
import GalleryShowCasePage from './gallery/GalleryShowCasePage.vue';
import TechShowCasePage from './gallery/TechShowCasePage.vue';

export default {
    name: 'OutputPage',
    components: {
        ResumePage,
        ShowCasePage,
        ProjectPage,
        WorkExperiencePage,
        GalleryShowCasePage,
        TechShowCasePage
    }
}
</script>

<style>
.portfolio-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.gallery-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
.gallery-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>
