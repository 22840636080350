<template>
    <div class="gallery-page">
    </div>
    <GalleryShowCasePage />
</template>

<script>
import GalleryShowCasePage from './gallery/GalleryShowCasePage.vue';

export default {
    name: 'galleryPage',
    components: {
        GalleryShowCasePage
    }
}
</script>

<style>
.gallery-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>
