<template>
    <div class="gallery-showcase">
        <div class="outer-container">
            <div class="header-section">
                <h2 class="header-title">技术向作品展示</h2>
                <hr class="divider-line"/>
            </div>

            <div v-for="(group, groupIndex) in processedGroups" :key="groupIndex">
                <!-- 分组标题 -->
                <h3 class="group-title" v-if="groupIndex > 0"><hr class="group-divider-line"/>{{ group.groupname }}</h3>
                <h3 class="group-title" v-else>{{ group.groupname }}</h3>

                <!-- 分组内的项目 -->
                <div v-for="(item, index) in group.groupImage" :key="index" class="project-item">
                    <!-- 当为视频时显示video标签 -->
                    <video v-if="item.isVideo" class="project-video" controls>
                        <source :src="item.imagePath" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>
                    <!-- 当为图片时显示el-image组件 -->
                    <el-image v-else :src="item.imagePath" class="project-image"></el-image>
                    <p class="project-description">{{ item.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import projectShowcaseData from '@/assets/datas/tech_showcase.json'; 

export default {
    name: 'TechShowCasePage',
    data() {
        return {
            projectGroups: projectShowcaseData
        };
    },
    computed: {
        processedGroups() {
            return this.projectGroups.map(group => ({
                ...group,
                groupImage: group.groupImage.map(item => ({
                    ...item,
                    isVideo: item.imagePath.endsWith('.mp4'), 
                    imagePath: require('@/assets/images/galaery/' + item.imagePath)
                }))
            }));
        }
    }
}
</script>


<style scoped>
.outer-container {
    width: 80%;
    max-width: 1080px;
    margin: 0 auto;
    border-top: 1px solid #ccc; /* 这里设置底部边框为none */
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc; /* 这里设置底部边框为none */
    padding: 20px;
}
@media print {
  .outer-container {
    width: 95%;
  }
}
.project-video {
    width: 100%; /* 或其他根据设计要求的宽度 */
    max-width: 800px; /* 根据需要调整 */
    height: auto; /* 保持视频的原始高宽比 */
}
.project-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}


.group-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-top: 20px;
}

.group-divider-line {
    width: 100%;
    border: 0.5px solid #ccc;
    margin-bottom: 10px;
}

.header-section {
    margin-bottom: 20px;
}

.header-title {
    font-size: 24px;
    margin-bottom: 10px;
    color: #525252;
}

.divider-line {
    width: 100%;
    border: 0.5px solid #ccc;
    margin-bottom: 20px;
}

.project-item {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-image {
    width: 80%;
    height: auto;
    margin-bottom: 10px;
}

.project-description {
    font-size: 12px;
    color: #999;
    text-align: center;
}
</style>
