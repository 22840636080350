<template>
    <div class="gallery-page">
    </div>
    <TechShowCasePage />
</template>

<script>
import TechShowCasePage from './gallery/TechShowCasePage.vue';

export default {
    name: 'techPage',
    components: {
        TechShowCasePage
    }
}
</script>

<style>
.gallery-page {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}
</style>
