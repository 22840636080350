<template>
  <div class="header">
    <el-menu class="menu-flex" mode="horizontal" default-active="2" :ellipsis="false">
      <el-menu-item index="1" class="home-icon" @click="goTo('resume')"></el-menu-item>
      <div class="flex-grow"></div>
      <el-menu-item index="2" @click="goTo('resume')">个人简历</el-menu-item>
      <el-menu-item index="3" @click="goTo('tech')">案例(技术向)</el-menu-item>
      <el-menu-item index="4" @click="goTo('gallery')">案例(美术向)</el-menu-item>
      <el-sub-menu index="5">
        <template #title>下载</template>
        <el-menu-item index="5-1" @click="downloadResume">下载简历</el-menu-item>
        <el-menu-item index="5-2" @click="downloadGallery">下载简历精简版(Boss直聘)</el-menu-item>
      </el-sub-menu>
    </el-menu>
  </div>
</template>



  
  <script>

  import resumePdf from '@/assets/datas/姜书亭-个人简历-Unity-客户端开发或技术美术岗.pdf';
  import artPdf from '@/assets/datas/姜书亭-个人简历-Unity-客户端开发或技术美术岗-精简版.pdf';
  export default {
    data() {
      return {
        dropdownActive: false
      };
    },
    methods: {
      goTo(page) {
        this.$router.push('/' + page);
      },
      downloadResume() {
        // 使用window.location.href直接导航到PDF文件的路径，触发浏览器的下载功能
        window.open(resumePdf, '_blank');
      },
      downloadGallery() {
        // 使用window.location.href直接导航到PDF文件的路径，触发浏览器的下载功能
        window.open(artPdf, '_blank');
      }
    }
  };
  </script>
  
  <style lang="scss">
      @import '@/scss/layouts/header.scss';    
  </style>
  