<template>
    <div class="tutorial-page">
      <el-carousel trigger="click" height="200px">
        <el-carousel-item v-for="item in 3" :key="item">
          <h3 class="medium">{{ '第' + item + '页' }}</h3>
        </el-carousel-item>
      </el-carousel>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TutorialPage'
  }
  </script>
  
  <style scoped>
  .medium {
    color: #fff;
    font-size: 14px;
    background-color: #99a9bf;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  </style>
  